export const categoryMock = {
  id: 'e655eee5-bfff-5f93-95d6-9f7cbe2e6d62',
  name: 'Hats',
  slug: 'hats',
  description:
    'Support your care routine with our skin care products. Whether you are looking for a moisturizing day cream or want to include a night cream in your beauty routine, we can help you achieve healthy skin with our products.',
  media: {
    mediaType: 'PHOTO',
    url: '533f13_83f54a5745b54553bbed019a5db91b8f~mv2.jpg',
    fullUrl:
      'https://static.wixstatic.com/media/c837a6_7db0aae63f414bb09ea1035911f0f3e7~mv2.jpg/v1/fit/w_1000,h_1300,q_90/file.jpg',
    height: 1300,
    width: 1000,
  },
  numOfProducts: 6,
  visible: true,
};
